body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.content {
  padding: 20px;
}

.header {
  padding: 10px;
  background: #edf2f4;
  border-bottom: 1px solid #999;
}
.header a {
  color: #0072ff;
  text-decoration: none;
  margin-left: 20px;
  margin-right: 5px;
}
.header a:hover {
  color: #8a0f53;
}
.header small {
  color: #666;
}
.header .active {
  color: #2c7613;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-link {
  color: inherit;
  text-decoration: inherit;
}

.text-sublink {
  color: inherit;
  text-decoration: inherit;
}

.result-snippet > div {
  white-space: normal;
  max-height: 3em;
}

.material-icons {
  vertical-align: text-top;
}

.flex-float-left {
  margin-right: auto;
}

.flex-float-right {
  margin-left: auto;
}

.userGuide {
  background-color: #999;
}

/* markdown (user guide) styling */
.markdown hr {
  border: 1px solid lightgray;
}

.markdown blockquote {
  padding-left: 12px;
  border-left: 2px solid #1f5492;
  background: aliceblue;
}

.markdown code {
  background: #ddd;
}

.markdown img {
  box-shadow: gray 0px 0px 4px;
  max-width: 100%;
}

.markdown h1 {
  color: #1f5492;
}

.markdown h2 {
  color: #1f5492;
  margin-top: 32px;
  margin-bottom: 8px;
}

.markdown h3 {
  margin-bottom: 8px;
}

.markdown h4 {
  font-style: italic;
  margin-bottom: 0px;
}

.markdown p {
  margin-top: 0px;
}

.blockquote {
  padding: 8px;
  border-left: 2px solid #1f5492;
  background: aliceblue;
}

.blockquote-error {
  padding: 8px;
  border-left: 2px solid red;
  background: #ffdddd;
}
